import firebase from 'firebase/app'

export default async function cleanData(payload) {
  const { collection } = payload
  const targetWord = '福岡県'
  const replaceWord = '福岡市'
  try {
    let cnt = 0
    await firebase.firestore().collection(collection)
      .where('勤務地1', '==', targetWord)
      .get()
      .then(sn => {
        if (!sn.empty) {
          sn.forEach(doc => {
            const data = doc.data()
            const { docID } = data
            if (data.勤務地2.indexOf(replaceWord) !== -1) {
              data.勤務地2 = replaceWord
              window.console.log('memo', data.勤務地2)
              firebase.firestore().collection(collection).doc(docID)
                .set(data, { merge: true })
              cnt += 1
            }
          })
        }
      })
    window.console.log('memo', cnt)
    const response = {
      status: 'success',
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: error,
    }
    return response
  }
}
