<template>
  <b-row class="match-height">
    <b-col lg="8">
      <Cleaning1
        class="mt-3 ml-1"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import Cleaning1 from '@/components/basic/data/Cleaning1.vue'

export default {
  components: {
    BRow,
    BCol,
    Cleaning1,
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss">
</style>
