<template>
  <div>
    <h2>市区町村のデータを正しく整形する</h2>
    例：京都市伏見区→京都市
    <b-row>
      <!-- submit and reset -->
      <b-col offset-md="4">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="excute"
        >
          実行
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import cleanData from '@/components/basic/data/js/clean1'

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  methods: {
    async excute() {
      const d = {
        collection: 'jobs',
      }
      const response = await cleanData(d)
      if (response.status === 'success') {
        window.console.log('success', response.status)
      } else {
        window.console.log('error', response.msg)
      }
    },
  },
}
</script>
